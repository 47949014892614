import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  ListingTypeBusinessUnitApproverSettings,
  ListingTypeBusinessUnitApproverSettingsCreateDataDto,
} from '@/types/Listing-type-business-unit-approvers.types';

export function getAllListingTypeBusinessUnitApprovers(): AxiosPromise<
  ListingTypeBusinessUnitApproverSettings[]
> {
  return axios.get(URLs.ListingTypeBusinessUnitApprovers, {});
}

export function setListingTypeBusinessUnitApprovers(
  data: ListingTypeBusinessUnitApproverSettingsCreateDataDto,
): AxiosPromise<ListingTypeBusinessUnitApproverSettings> {
  return axios.post(URLs.ListingTypeBusinessUnitApproversUpdate, data.approvers, {
    params: { listingTypeId: data.listingTypeId, businessUnitId: data.businessUnitId },
  });
}

export function deleteListingTypeBusinessUnitApprovers(
  listingTypeId: number,
  businessUnitId: number,
): AxiosPromise<ListingTypeBusinessUnitApproverSettings> {
  return axios.delete(URLs.ListingTypeBusinessUnitApproversDelete, {
    params: { listingTypeId: listingTypeId, businessUnitId: businessUnitId },
  });
}
