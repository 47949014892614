
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import GImageUpload from '@/components/gsk-components/GskImageUpload.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import {
  getAllListingTypeBusinessUnitApprovers,
  setListingTypeBusinessUnitApprovers,
  deleteListingTypeBusinessUnitApprovers,
} from '@/api/listing-type-business-unit-approvers.api';
import { TextField, SelectField, PeoplePickerField } from '@/components/form/form.types';
import {
  ListingTypeBusinessUnitApproverSettings,
  ListingTypeBusinessUnitApproverSettingsCreateDataDto,
} from '@/types/Listing-type-business-unit-approvers.types';
import { openErrorSnackbar } from '@/utils/components';
import { EnumsModule } from '@/store/modules/enums.module';
import UserList from '@/components/UserList.vue';

type NewListingTypesForm = [
  SelectField<'listingTypeId'>,
  SelectField<'businessUnitId'>,
  PeoplePickerField<'approvers'>,
];

type editListingTypeForm = [
  SelectField<'listingTypeId'>,
  SelectField<'businessUnitId'>,
  PeoplePickerField<'approvers'>,
];

@Component({
  components: {
    GButton,
    MaxWidth,
    Promised,
    ValidatedFormDialog,
    DeleteDialog,
    GskImageUpload: GImageUpload,
    UserList,
  },
})
export default class ListingTypeApproversAdministrationView extends Vue {
  newListingTypeOpen = false;
  open = false;
  listingTypes: Promise<ListingTypeBusinessUnitApproverSettings[]> | null = null;

  newListingTypesForm: NewListingTypesForm = [
    {
      key: 'listingTypeId',
      label: 'Listing Type',
      type: 'select',
      value: '',
      required: true,
      options: Object.entries(EnumsModule.enums.listingType).map(([key, value]) => {
        return {
          value: value.id.toString(),
          label: key,
        };
      }),
      validation: {
        rules: 'required',
      },
    },
    {
      key: 'businessUnitId',
      label: 'Business Unit',
      type: 'select',
      value: '',
      required: true,
      options: Object.entries(EnumsModule.enums.businessUnit).map(([key, value]) => {
        return {
          value: value.id.toString(),
          label: key,
        };
      }),
      validation: {
        rules: 'required',
      },
    },
    {
      key: 'approvers',
      label: 'Approvers',
      type: 'people-picker',
      value: [],
      required: true,
      validation: {
        rules: 'required',
      },
    },
  ];
  get newListingTypeFormData(): ListingTypeBusinessUnitApproverSettings {
    const out: ListingTypeBusinessUnitApproverSettings | any = {
      listingTypeId: 0,
      businessUnitId: 0,
      approvers: [
        {
          fullName: '',
          firstName: '',
          lastName: '',
          mudId: '',
          email: '',
        },
      ],
    };
    this.newListingTypesForm.forEach(f => {
      if (f.key === 'businessUnitId') {
        out[f.key] = parseInt(f.value);
      } else if (f.key === 'listingTypeId') {
        out[f.key] = parseInt(f.value);
      } else {
        f.value[0].roleId = 1;
        delete f.value[0].extra;
        out[f.key] = f.value;
      }
    });
    return out;
  }

  createListingType() {
    setListingTypeBusinessUnitApprovers(this.newListingTypeFormData)
      .then(() => {
        this.newListingTypeOpen = false;
        this.load();
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.toString());
      });
  }

  editListingTypeOpen = false;
  editListingTypeFormData: ListingTypeBusinessUnitApproverSettings | null = null;
  editListingTypeForm: editListingTypeForm | null = null;
  setEditRole(role: ListingTypeBusinessUnitApproverSettingsCreateDataDto) {
    this.editListingTypeFormData = role;
    this.editListingTypeForm = [
      {
        key: 'listingTypeId',
        label: 'Listing Type',
        type: 'select',
        value: JSON.stringify(role.listingTypeId),
        required: true,
        options: EnumsModule.enumsSelectOptions.listingType,
        validation: {
          rules: 'required',
        },
      },
      {
        key: 'businessUnitId',
        label: 'Business Unit',
        type: 'select',
        value: JSON.stringify(role.businessUnitId),
        required: true,
        options: EnumsModule.enumsSelectOptions.businessUnit,
        validation: {
          rules: 'required',
        },
      },
      {
        key: 'approvers',
        label: 'Approvers',
        type: 'people-picker',
        value: role.approvers,
        required: true,
        validation: {
          rules: 'required',
        },
      },
    ];
    this.editListingTypeOpen = true;
  }

  get editListingBusinessTypeFormData(): ListingTypeBusinessUnitApproverSettings | null {
    if (this.editListingTypeFormData && this.editListingTypeForm) {
      const out: ListingTypeBusinessUnitApproverSettings | any = {
        listingTypeId: 0,
        businessUnitId: 0,
        approvers: [
          {
            fullName: '',
            firstName: '',
            lastName: '',
            mudId: '',
            email: '',
          },
        ],
      };
      for (const f of this.editListingTypeForm) {
        if (f.key === 'businessUnitId') {
          out[f.key] = parseInt(f.value);
        } else if (f.key === 'listingTypeId') {
          out[f.key] = parseInt(f.value);
        } else {
          f.value[0].roleId = 1;
          delete f.value[0].extra;
          out[f.key] = f.value;
        }
      }
      return out;
    }
    return null;
  }

  updateListingType() {
    if (this.editListingBusinessTypeFormData) {
      setListingTypeBusinessUnitApprovers(this.editListingBusinessTypeFormData)
        .then(() => {
          this.editListingTypeOpen = false;
          this.load();
        })
        .catch(e => {
          openErrorSnackbar.call(this, e.toString());
        });
    }
  }

  private deleteListingTypeId = 0;
  private deleteBusinessUnitId = 0;
  private deleteOpenType = false;

  deleteListingType() {
    deleteListingTypeBusinessUnitApprovers(this.deleteListingTypeId, this.deleteBusinessUnitId)
      .then(response => {
        const success = response.data;
        if (!success) {
          openErrorSnackbar.call(
            this,
            'Cannot delete listing type: server delete function returned false',
          );
        }
        this.deleteOpenType = false;
        this.deleteListingTypeId = 0;
        this.deleteBusinessUnitId = 0;
        this.load();
      })
      .catch(e => {
        openErrorSnackbar.call(this, e.toString());
      });
  }

  setDeleteListingType(listingTypeId: number, businessUnitId: number) {
    this.deleteOpenType = true;
    this.deleteListingTypeId = listingTypeId;
    this.deleteBusinessUnitId = businessUnitId;
  }

  // From Approval.vue
  load() {
    this.listingTypes = getAllListingTypeBusinessUnitApprovers()
      .then(r => {
        return r.data;
      })
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
  }
  created() {
    this.load();
  }
}
